// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gianfaye-gatsby-theme-src-templates-article-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/article.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-article-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-articles-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/articles.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-articles-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-category-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/category.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-category-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-index-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/index.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-index-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-project-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/project.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-project-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-projects-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/projects.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-projects-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-topic-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/topic.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-topic-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-topics-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/topics.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-topics-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-work-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/work.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-work-template-tsx" */),
  "component---gianfaye-gatsby-theme-src-templates-works-template-tsx": () => import("./../../../../@gianfaye/gatsby-theme/src/templates/works.template.tsx" /* webpackChunkName: "component---gianfaye-gatsby-theme-src-templates-works-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-timeline-tsx": () => import("./../../../src/pages/timeline.tsx" /* webpackChunkName: "component---src-pages-timeline-tsx" */)
}

